import React from 'react'
import Page from '../components/Page/Page'
import { graphql } from 'gatsby'

import './kontakt.module.css'

const KontaktPage = ({ data }) => {
  const formLabels = data.contentfulKontaktSeite;
  return (
    <Page
      title={formLabels.pageTitle}
      renderCallback={() => (
        <div styleName={'pageContainer'}>
          <div styleName={'mapContainer'}>
            <div styleName={'title'}>{formLabels.anfahrtTitleLabel}</div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2659.2226511866!2d16.37557861641154!3d48.2023271792286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d077651cc44bd%3A0xc35e601991551108!2sGeigenbau%20Walter%20Neubauer!5e0!3m2!1sde!2sat!4v1652883356534!5m2!1sde!2sat"
              style={{width: "600px", height: "500px", border:"0"}}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </div>
          <div styleName={'contactFormContainer'}>
            <div styleName={'title'}>{formLabels.formTitleLabel}</div>
            <form styleName={'contactForm'} action="https://getform.io/f/5af7af93-ff64-49fb-803f-c3e11fac66b4" method="POST">
              <div styleName={'radioContainer'}>
                <div>
                  <input type="radio" name="gender" value={formLabels.genderFeld[0]} />
                  <span>{formLabels.genderFeld[0]}</span>
                </div>
                <div styleName={'spacer'}/>
                <div>
                  <input type="radio" name="gender" value={formLabels.genderFeld[1]} />
                  <span>{formLabels.genderFeld[1]}</span>
                </div>
              </div>
              <div styleName={'formElement'}>
                <div>{formLabels.nameFeld}</div>
                <input type="text" name="name" placeholder={formLabels.nameFeldPlaceholder} required/>
              </div>
              <div styleName={'formElement'}>
                <div>{formLabels.emailFeld}</div>
                <input type="email" name="email" placeholder={formLabels.emailFeldPlaceholder} required/>
              </div>
              <div styleName={'formElement'}>
                <div>{formLabels.nachrichtFeld}</div>
                <textarea type="text" name="message" placeholder={formLabels.nachrichtFeldPlaceholder}></textarea>
              </div>
              <button type="submit">{formLabels.sendenButtonText}</button>
            </form>
          </div>
        </div>
      )}
    />
  )
}

export default KontaktPage

export const kontaktQueryFragment = graphql `
  fragment KontaktQueryFragment on ContentfulKontaktSeite {
    pageTitle
    emailAlias
    emailFeld
    emailFeldPlaceholder
    genderFeld
    nachrichtFeld
    nachrichtFeldPlaceholder
    nameFeld
    nameFeldPlaceholder
    sendenButtonText
    bestaetigungsNachricht
    anfahrtTitleLabel
    formTitleLabel
  }
`

export const kontaktQuery = graphql `
  query kontaktDEQuery {
    contentfulKontaktSeite(node_locale: {eq: "de"}) {
      ...KontaktQueryFragment
    }
  }
`
